import type { RequestBuilder } from "@lernetz/request";
import { get, type Writable } from "svelte/store";
import { requests } from "../services";
import { type CoAuthorShareStore, type MaterialStore, processIn } from "./mapping";
import type { Image, MaterialState, Publication } from "./types";

export type SearchParams = {
    page: number;
    pageSize: number;
    term: string;
    levels: string[];
    areas: string[];
    sortDir: "desc" | "asc";
    favouritesOnly: boolean;
    selectedTags: Array<String>;
    limit: number;
    offset: number;
    theme: string;
};

export type SearchResponse = {
    hits: MaterialStore[];
    facetDistribution: { tags: FacetDistribution };
    estimatedTotalHits: number;
};

export type FacetDistribution = {
    [key: string]: number;
};

export class MaterialGateway {
    base: RequestBuilder;

    constructor(base: RequestBuilder) {
        this.base = base;
    }

    hasDiff(material: MaterialStore) {
        return this.base.jsonApi<{ hasDiff: boolean }>("hasDiff", {
            id: get(material).id,
            modelName: get(material).modelName,
        });
    }

    submit(material: MaterialStore, anonymous: boolean = false): Promise<void> {
        return this.base.jsonApi("submit", { id: get(material).id, anonymous });
    }

    snapshot(material: MaterialStore, message = ""): Promise<{}> {
        return this.base.jsonApi("snapshot", {
            id: get(material).id,
            modelName: get(material).modelName,
            message,
        });
    }

    allSnapshots(material: MaterialStore) {
        return this.base.jsonApi<Publication[]>("allSnapshots", {
            id: get(material).id,
            modelName: get(material).modelName,
        });
    }

    publish(material: MaterialStore): Promise<void> {
        return this.base.jsonApi("publish", { id: get(material).id });
    }

    unpublish(material: MaterialStore): Promise<void> {
        return this.base.jsonApi("unpublish", { id: get(material).id });
    }

    clone(material: MaterialStore): Promise<MaterialStore> {
        return this.base.jsonApi("clone", { id: get(material).id }).then(processIn);
    }

    getTemplates() {
        return this.base.jsonApi<Array<MaterialStore>>("templates").then(processIn);
    }

    getSubmitted({ offset, limit }: { offset: number; limit: number }) {
        return this.base
            .jsonApi<{ results: MaterialStore[]; total: number }>("submitted", {
                offset,
                limit,
            })
            .then((res) => ({
                results: processIn(res.results) as MaterialStore[],
                total: res.total,
            }));
    }

    sendMailForShare(share: CoAuthorShareStore) {
        return requests.api.share.jsonApi("sendmail", {
            hash: get(share).hash,
        });
    }

    searchMine(searchparam: Partial<SearchParams>) {
        return this.base.jsonApi<SearchResponse>("searchMine", { searchparam }).then(processIn);
    }

    search(searchparam: Partial<SearchParams>) {
        return this.base.jsonApi("search", { searchparam }).then<SearchResponse>(processIn);
    }

    stats(m: MaterialStore) {
        return requests.api.stats.jsonApi<{
            views: number;
            clones: number;
            saves: number;
            state: MaterialState;
        }>("", { id: get(m).id });
    }

    images(m: MaterialStore): Promise<Array<Writable<Image & { chapter?: number }>>> {
        return requests.api.material
            .relative("/{id}/images")
            .vars({ id: get(m).id })
            .method("GET")
            .fetch()
            .then((res) => res.json())
            .then<Array<Image & { chapter?: number }>>((json) => json?.data)
            .then(processIn);
    }
}
