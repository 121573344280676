import {} from "@lernetz/svelte-model-api";
import { processIn } from "./mapping";
import { type DBModel } from "@lernetz/svelte-model-api/createModelApi";
import type { RequestBuilder } from "@lernetz/request";

export class AssetsGateway {
    base: RequestBuilder;

    constructor(base: RequestBuilder) {
        this.base = base;
    }

    import(url: string, metadata?: {}) {
        return this.base.jsonApi("import", { url, metadata }).then<DBModel>(processIn);
    }

    upload(files: FileList, progressReporter?: { progress: number }) {
        let request = this.base.relative("upload").method("POST");

        let data = new FormData();

        for (const file of files) {
            data.append("files[]", file, file.name);
        }

        return request
            .headers({ Accept: "application/json" })
            .body(data)
            .fetch()
            .then((response) => response.json())
            .then<DBModel[]>(processIn);
    }
}
