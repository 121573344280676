import { RequestBuilder } from "@lernetz/request";
import { depthFirst } from "@lernetz/svelte-common";
import type { DBModel } from "@lernetz/svelte-model-api/createModelApi";
import { wrapStore } from "@lernetz/svelte-model-api/process";
import {
    attributeProgress,
    composeCorrectable,
    composeInputable,
    composeProgressable,
    composeResetable,
    isProgressable,
} from "@lernetz/svelte-user-input";
import { observeUserdata } from "@lernetz/svelte-userdata-api";
import { derived, get, readable, type Writable, writable } from "svelte/store";
import { modelApi, requests, user, userdataGateway } from "../services";
import { trackEvent } from "../utils/Tracker";
import type {
    Asset,
    Chapter,
    Choice,
    CoAuthorShare,
    Code,
    Foldable,
    Freetext,
    H5P,
    Heading,
    HighlightedText,
    IFrame,
    Image,
    Learningapps,
    Material,
    Math,
    MultipleChoice,
    PublicShare,
    SelfAssessment,
    SelfAssessmentAnswers,
    SelfAssessmentQuestions,
    Separator,
    SingleChoice,
    Text,
    User,
} from "./types";

export type MapFunction = (d: any) => any;
const map = new Map<string, MapFunction>();

map.set("App\\Material", createMaterial);
map.set("App\\Chapter", createChapter);
map.set("App\\Shares\\PublicShare", createPublicShare);
map.set("App\\Shares\\CoAuthorShare", createCoAuthorShare);
map.set("App\\SingleChoice", createSingleChoice);
map.set("App\\MultipleChoice", createMultipleChoice);
map.set("App\\Freetext", createFreetext);
map.set("App\\SelfAssessment", createSelfAssessment);
map.set("App\\Choice", createChoice);
map.set("App\\H5P", createH5P);

export const processIn = <T = any>(dto: any): T =>
    depthFirst(dto, (i) => {
        if (map.has(i?.modelName)) return map.get(i?.modelName)(i);
        return wrapStore(i);
    });

function createMaterial(dto: Material) {
    const s = writable({
        ...dto,
        share: dto.shares?.filter((s) => !get(s).permission)[0],
        hash: get(dto.shares?.[0])?.hash,
    });

    const themeColor = derived(s, ($s) => {
        // if there is a theme use the material color
        return $s.theme != "" ? $s.theme : $s.color;
    });

    // if there is no user no favourites.
    let isFavourite = readable(false);

    // if there is a user check if the material is a favourite
    if (user) {
        isFavourite = derived([s, user], ([$s, $u]) => {
            return (
                $u &&
                $u.favMaterialIds &&
                !!$u.favMaterialIds.find((material) => material === $s.id)
            );
        });
    }

    const toggleFavourite = () => {
        const active = get(isFavourite);
        const matId = get(s).id;
        const currentFavs = get(user).favMaterialIds.slice();

        if (active) {
            currentFavs.splice(currentFavs.indexOf(matId), 1);
        } else {
            currentFavs.push(matId);
        }

        modelApi.model.update(user, {
            favMaterialIds: currentFavs,
        });

        trackEvent("Material als Favorit markieren", {
            props: {
                activate: active,
            },
        });
    };

    const generateShareUrl = (
        params: { sidebar?: "open"; version?: string; chapter?: ChapterStore } = {},
    ) => {
        const share = get(s).share;

        let request = new RequestBuilder(get(share).url);

        const { sidebar, version, chapter } = params;

        if (sidebar) request = request.query(new URLSearchParams({ sidebar }));
        if (version) request = request.query(new URLSearchParams({ version }));
        if (chapter) request = request.relative(get(chapter).id);

        return request.url;
    };

    const isOwner = (u: UserStore = user) => {
        return u && get(u).id === get(get(s).owner).id;
    };

    const material = {
        ...s,
        ...composeProgressable(get(s).chapters),
        isFavourite,
        toggleFavourite,
        generateShareUrl,
        isOwner,
        themeColor,
    };

    return material;
}

function createChapter(dto: Chapter) {
    const s = writable({
        ...dto,
    });

    // check if there are nested progressable elements
    const trackScroll = get(s)?.content?.filter(isProgressable).length === 0;

    // if there are no nested progressable elements, use the chapter progress
    const progress = trackScroll
        ? { progress: derived(s, ($s) => $s.progress || 0) }
        : composeProgressable(get(s).content);

    observeUserdata(s, "progress", userdataGateway);

    return {
        ...s,
        ...progress,
        trackScroll,
    };
}

function createPublicShare(dto: PublicShare) {
    const s = writable({
        ...dto,
        url: requests.start.vars({ hash: dto.hash, mode: "" }).url,
    });

    return s;
}

function createCoAuthorShare(dto: CoAuthorShare) {
    const s = writable({
        ...dto,
        expires_at: new Date(dto.expires_at),
        url: requests.share.accept.vars({ hash: dto.hash }).url,
    });

    return s;
}

function createSingleChoice(dto: SingleChoice) {
    const s = createMultipleChoice<SingleChoice>({
        sortedChoices: dto.isPoll ? dto.choices : dto.choices?.sort(() => Math.random() - 0.5),
        ...dto,
    });

    // add listener to choices to only allow one choice to be correct
    onlyOneTrue(get(s).choices, "correct", (c) => modelApi.model.update(c, { correct: false }));
    onlyOneTrue(get(s).choices, "selected", (c) => c.update((c) => ({ ...c, selected: false })));

    return s;
}

function createMultipleChoice<T extends MultipleChoice>(dto: T) {
    const s = writable({
        sortedChoices: dto.choices?.sort(() => Math.random() - 0.5),
        ...dto,
    });

    observeUserdata(s, "evaluated", userdataGateway);

    return {
        ...s,
        ...composeCorrectable(get(s).choices),
        ...composeInputable(get(s).choices),
        ...attributeProgress(s, "evaluated"),
        reset: () => {
            composeResetable(get(s).choices).reset();
            s.update((s) => ({ ...s, evaluated: false }));
        },
    };
}

function createChoice(dto: Choice) {
    const s = writable({
        ...dto,
    });

    observeUserdata(s, "selected", userdataGateway);

    return {
        ...s,
        isCorrect: derived(s, ($c) => !!$c.correct === !!$c.selected),
        hasInput: derived(s, (c) => !!c.selected),
        reset: () => s.update(($s) => ({ ...$s, selected: false })),
    };
}

function onlyOneTrue<T extends DBModel>(
    choices: Writable<T>[] = [],
    attr: keyof T,
    update: (c: Writable<T>) => void,
) {
    choices.forEach((choice) => {
        choice.subscribe((c) => {
            // if the attribute is true, set all other choices to false
            if (c[attr] === true) {
                choices.forEach((c, j) => {
                    // only update others and the true ones
                    if (choice !== c && get(c)[attr] === true) update(c);
                });
            }
        });
    });
}

function createFreetext(dto: Freetext) {
    const s = writable({
        ...dto,
    });

    observeUserdata(s, "evaluated", userdataGateway);
    observeUserdata(s, "text", userdataGateway);

    return {
        ...s,
        ...attributeProgress(s, "evaluated"),
        hasInput: derived(s, ($s) => !!$s.text),
        reset: () => {
            s.update((s) => {
                s.evaluated = false;
                s.text = "";
                return s;
            });
        },
    };
}

function createSelfAssessment(dto: SelfAssessment) {
    const s = writable({
        ...dto,
    });

    observeUserdata(s, "selection", userdataGateway);

    return {
        ...s,
        hasInput: derived(s, ($s) => $s.selection?.length > 0),
        progress: derived(s, ($s) => ($s.selection?.length || 0) / $s.questions.length),
        reset: () => {
            s.update((s) => ({ ...s, selection: [] }));
        },
    };
}

function createH5P(dto: H5P) {
    const s = writable({
        ...dto,
    });

    observeUserdata(s, "evaluated", userdataGateway);
    observeUserdata(s, "result", userdataGateway);

    return {
        ...s,
        ...attributeProgress(s, "evaluated"),
    };
}

export type MaterialStore = ReturnType<typeof createMaterial>;
export type ChapterStore = ReturnType<typeof createChapter>;
export type UserStore = Writable<User>;
export type AssetStore = Writable<Asset>;
export type PublicShareStore = ReturnType<typeof createPublicShare>;
export type CoAuthorShareStore = Writable<CoAuthorShare>;
export type ImageStore = Writable<Image>;
export type HeadingStore = Writable<Heading>;
export type TextStore = Writable<Text>;
export type IFrameStore = Writable<IFrame>;
export type H5PStore = ReturnType<typeof createH5P>;
export type MathStore = Writable<Math>;
export type LearningappsStore = Writable<Learningapps>;
export type FreetextStore = ReturnType<typeof createFreetext>;
export type ChoiceStore = ReturnType<typeof createChoice>;
export type SingleChoiceStore = ReturnType<typeof createSingleChoice>;
export type MultipleChoiceStore = ReturnType<typeof createMultipleChoice>;
export type SelfAssessmentStore = ReturnType<typeof createSelfAssessment>;
export type SelfAssessmentAnswersStore = Writable<SelfAssessmentAnswers>;
export type SelfAssessmentQuestionsStore = Writable<SelfAssessmentQuestions>;
export type HighlightedTextStore = Writable<HighlightedText>;
export type SeparatorStore = Writable<Separator>;
export type FoldableStore = Writable<Foldable>;
export type CodeStore = Writable<Code>;
