<script lang="ts">
  import { page } from "@inertiajs/svelte";
  import { init } from "../services";
  import Icons from "../icons/Icons.svelte";

  init($page.props.setup);
</script>

<div class="hidden">
  <Icons />
</div>
<slot />
