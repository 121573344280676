import { mapRequests, RequestBuilder } from "@lernetz/request";
import { createModelApi, type ModelApiType } from "@lernetz/svelte-model-api";
import { createGateway, withMetadata, withQueue } from "@lernetz/svelte-userdata-api";
import { writable } from "svelte/store";
import { AssetsGateway } from "./gateway/AssetsGateway";
import { processIn, type UserStore } from "./gateway/mapping";
import { MaterialGateway } from "./gateway/MaterialGateway";
import type { User } from "./gateway/types";

export let modelApi: ModelApiType;
export let materialGateway: MaterialGateway;
export let assetsGateway: AssetsGateway;
export let userdataGateway: ReturnType<typeof createGateway> &
    ReturnType<typeof withMetadata> &
    ReturnType<typeof withQueue>;

export const hasPublishedMaterials = writable(false);

export let requests: {
    api: {
        model: RequestBuilder;
        material: RequestBuilder;
        assets: RequestBuilder;
        share: RequestBuilder;
        stats: RequestBuilder;
        viewer: RequestBuilder;
    };
    file: {
        image: RequestBuilder;
    };
    h5p: {
        create: RequestBuilder;
        edit: RequestBuilder;
        embed: RequestBuilder;
    };
    share: {
        accept: RequestBuilder;
    };
    start: RequestBuilder;
    scorm: RequestBuilder;
    images: RequestBuilder;
    url2pdf: RequestBuilder;
    choicePollReset: RequestBuilder;
    password: RequestBuilder;
};
export let user: UserStore;

export type Setup = {
    routes: any;
    lang: any;
    user: User;
    metadata: Record<string, any>;
    hasPublishedMaterials: boolean;
};
export function init(data: Setup) {
    if (!data) return;

    //const mappedData = processIn( config.data );
    requests = mapRequests(data.routes);
    user = processIn(data.user);

    modelApi = createModelApi({
        base: requests.api.model,
        inFn: processIn,
    });

    materialGateway = new MaterialGateway(requests.api.material);
    assetsGateway = new AssetsGateway(requests.api.assets);
    userdataGateway = withQueue(withMetadata(data.metadata, createGateway(requests.api.viewer)));

    // hold userdataGateway if the is no code yet
    if (!data.metadata?.code) userdataGateway.hold();

    hasPublishedMaterials.set(data.hasPublishedMaterials);
}
