import { createInertiaApp } from "@inertiajs/svelte";
import "vite/modulepreload-polyfill";
import SetupLayout from "./layout/SetupLayout.svelte";

import type { SvelteComponent } from "svelte";
import "../styling/main.css";

interface ModuleImportInterface {
    default: SvelteComponent;
    layout?: SvelteComponent;
}

createInertiaApp({
    resolve: async (name) => {
        const pages = import.meta.glob<ModuleImportInterface>("./pages/**/*Page.svelte");
        const page = await pages[`./pages/${name}.svelte`]();
        return {
            default: page.default,
            layout: page.layout ? [SetupLayout, page.layout] : SetupLayout,
        };
    },
    setup({ el, App, props }) {
        new App({ target: el, props });
    },
});
